/** @jsx jsx */

import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import {jsx, Styled} from 'theme-ui'
import {Layout, SEO} from '../components'
import {Container} from '../elements'
import Navigation from '../components/navigation'

const {langPrefix} = require('../utils/lang-prefix')

const BlogSite = ({data: {posts, Blog, i18nPathname}, location}) => {
  const {lang} = Blog
  return (
    <Layout>
      <Navigation lang={lang} i18nPathname={i18nPathname.nodes} />
      <SEO data={Blog.data} pathname={location.pathname} />
      <div
        sx={{
          mb: [(t) => t.layout.separation.mobile, '', '8rem'],
        }}
      >
        <div
          sx={{
            backgroundColor: 'primary',
            p: ['7rem 1.5rem 5rem 1.5rem', '', '12rem 1.5rem 8rem 1.5rem'],
          }}
        >
          <div
            sx={{
              maxWidth: (t) => t.layout.big,
              m: '0 auto',
            }}
          >
            <h1
              sx={{
                fontSize: ['1rem', '', '1.5rem'],
                lineHeight: 1.4,
                color: '#fde3ce',
                m: ['0 0 0.4rem 0', '', '0'],
              }}
            >
              {Blog.data.header_title}
            </h1>
            <h2
              sx={{
                fontSize: ['2rem', '', '2.5rem'],
                lineHeight: 1.25,
                color: (t) => t.colors.gray[1],
                m: 0,
              }}
            >
              {Blog.data.header_subtitle}
            </h2>
            <hr
              sx={{
                backgroundColor: '#fff',
                height: '4px',
                width: ['calc(100vw - 6rem)', '', '12rem'],
                margin: '0.25rem 0 0 0',
                padding: '0',
                border: 0,
              }}
            />
          </div>
        </div>
      </div>

      <Container type="big">
        {posts.edges.map((item) => (
          <div
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              marginBottom: '4rem',
            }}
          >
            {/* Image */}
            <div
              sx={{
                position: 'relative',
                mb: ['3rem', '', '', '0'],
                minHeight: '300px',
                transition: (t) => t.transitions.boom.transition,
                borderRadius: (t) => t.borderRadius.default,
                boxShadow: '0 14px 30px rgba(0, 0, 0, 0.18)',
                img: {
                  borderRadius: (t) => t.borderRadius.default,
                },
                '&:hover': {
                  boxShadow: '0 18px 48px rgba(0, 0, 0, 0.18)',
                  transform: 'translateY(-12px)',
                },
                a: {
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  borderRadius: `${(t) => t.borderRadius.default}`,
                  '& > div': {
                    position: 'static !important',
                    pb: '0 !important',
                  },
                  '& > div > div': {
                    position: 'static !important',
                    pb: '0 !important',
                  },
                  '&:focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 5px ${(t) => t.colors.primary}`,
                  },
                },
                flexBasis: 'calc(99.9% * 2 / 5 - 1rem)',
                maxWidth: 'calc(99.9% * 2 / 5 - 1rem)',
                width: 'calc(99.9% * 2 / 5 - 1rem)',
                '@media (max-width: 800px)': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                  width: '100%',
                },
              }}
            >
              <Link to={`${langPrefix(item.node.uid, lang, 'blog')}`}>
                <Img
                  fluid={
                    item.node.data.post_image.localFile.childImageSharp.fluid
                  }
                />
              </Link>
            </div>
            {/* Content */}
            <div
              sx={{
                flexBasis: 'calc(99.9% * 3 / 5 - 1rem)',
                maxWidth: 'calc(99.9% * 3 / 5 - 1rem)',
                width: 'calc(99.9% * 3 / 5 - 1rem)',
                '@media (max-width: 800px)': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                  width: '100%',
                },
              }}
            >
              <Link to={`${langPrefix(item.node.uid, lang, 'blog')}`}>
                <h1
                  sx={{
                    fontSize: ['1.5rem', '', '2.25rem'],
                    color: (t) => t.colors.gray[8],
                    lineHeight: 1.3,
                    mt: 0,
                    mb: '0.8rem',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      color: '#e36d0d',
                    },
                  }}
                >
                  {item.node.data.cb_title}
                </h1>
              </Link>
              <span
                sx={{
                  color: (t) => t.colors.gray[6],
                  fontSize: '1.25rem',
                }}
              >
                {item.node.first_publication_date} — Kategorie:{' '}
                {item.node.tags.map((tag, i) =>
                  i + 1 < item.node.tags.length ? `${tag}, ` : tag
                )}
              </span>
              <Styled.p
                sx={{
                  mt: '1.5rem',
                  color: (t) => t.colors.gray[8],
                }}
              >
                {item.node.data.cb_desc}
              </Styled.p>
            </div>
          </div>
        ))}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Blog($lang: String) {
    i18nPathname: allPrismicBlogSite {
      nodes {
        uid
        lang
      }
    }

    Blog: prismicBlogSite(lang: {eq: $lang}) {
      uid
      lang
      data {
        header_title
        header_subtitle

        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }
    posts: allPrismicBlogeintrag(
      filter: {lang: {eq: $lang}}
      sort: {order: DESC, fields: last_publication_date}
    ) {
      edges {
        node {
          uid
          first_publication_date(formatString: "DD.MM.YYYY")
          tags

          data {
            cb_title
            cb_desc
            post_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            body1 {
              ... on PrismicBlogeintragBody1Text {
                slice_type
                id
                primary {
                  text {
                    html
                  }
                }
              }
              ... on PrismicBlogeintragBody1Image {
                slice_type
                id
                primary {
                  image {
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicBlogeintragBody1Quote {
                id
                primary {
                  quote {
                    text
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
`

export default BlogSite
